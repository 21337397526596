import sessionIdConstants from '../utilities/sessionIdConstants';
import { MSWConfig } from './typings';

const { SESSION_ID_MSW } = sessionIdConstants;

const defaultMSWConfig: MSWConfig = { MSW_ENABLED: false };

/** Retrieve MSW config object from session storage */
export const getMswConfig = (): MSWConfig => {
  const sessionConfig: MSWConfig = JSON.parse(sessionStorage.getItem(SESSION_ID_MSW));
  if (sessionConfig === null) {
    sessionStorage.setItem(SESSION_ID_MSW, JSON.stringify(defaultMSWConfig));
    return defaultMSWConfig;
  }
  return sessionConfig;
};

/** Overwrite entire MSW config */
export const setMswConfig = (config: MSWConfig): void => {
  sessionStorage.setItem(SESSION_ID_MSW, JSON.stringify(config));
};

/** Overwrite just one property on MSW config */
export const setMswConfigFlag = (flag: string, value: boolean | string): void => {
  const config = getMswConfig();
  const newProperty = { [flag]: value };
  setMswConfig({ ...config, ...newProperty });
};
