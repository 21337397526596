import React, { useState } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import useEffectOnce from '../../utilities/reactHooks';
import useStyles from './ControlPanel.styles';
import { startMSW, getMswConfig, setMswConfigFlag } from '../../mocks';

const MockServiceWorkerPanel = () => {
  const classes = useStyles();
  const [mswConfig, setMswConfig] = useState(getMswConfig());

  useEffectOnce(() => {
    if (mswConfig.MSW_ENABLED) startMSW();
  });

  const updateMswConfigFlag = (flagName: string, value: string) => {
    const newValue = !value;
    setMswConfigFlag(flagName, newValue);
    setMswConfig({ ...mswConfig, [flagName]: newValue });
  };

  return (
    <>
      {Object.entries(mswConfig).map(([key]) => {
        return (
          <FormControlLabel
            className={classes.flagLabel}
            key={key}
            control={
              <Switch
                checked={mswConfig[key] === true}
                onChange={() => updateMswConfigFlag(key, mswConfig[key])}
                name={key}
              />
            }
            label={key}
          />
        );
      })}
    </>
  );
};

export default MockServiceWorkerPanel;
